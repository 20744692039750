import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { SectionHeader } from "../../components/SectionHeader";
import { TransactionList } from "../../components/TransactionList";
import { sortDate } from "../../utils/date";
import { useData } from "../../utils/useData";

export const Account = () => {
  const { accountId } = useParams();
  const { transactions } = useData();

  const accountTransactions = transactions.data?.items
    .filter(
      (transaction) =>
        transaction?.accountId === accountId ||
        transaction?.charges?.find((c) => c.accountId === accountId)
    )
    ?.sort((a, b) => sortDate({ dateA: b.timestamp, dateB: a.timestamp }));

  if (!accountId) return <></>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionHeader text="Transaction history" showBackButton={true} />
        <TransactionList transactions={accountTransactions ?? []} />
      </Grid>
    </Grid>
  );
};
