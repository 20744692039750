import { Route, Routes as Router } from "react-router-dom";
import { LoadingSpinner } from "./components/LoadingSpinner";
import { Account } from "./pages/account/Account";
import { ActivateCard } from "./pages/activate-card/ActivateCard";
import { Home } from "./pages/home/Home";
import { Profile } from "./pages/profile/Profile";
import { ResetPin } from "./pages/reset-pin/ResetPin";
import { useData } from "./utils/useData";

export const Routes = () => {
  const { userInfoData, userInfo } = useData();

  if (userInfo.isIdle || userInfo.isLoading) return <LoadingSpinner />;

  return (
    <Router>
      <Route path="/" Component={() => <Home userInfoData={userInfoData!} />} />
      <Route path="/account/:accountId" Component={Account} />
      <Route path="/profile" Component={Profile} />
      <Route path="/activate-card" Component={ActivateCard} />
      <Route path="/reset-pin" Component={ResetPin} />
    </Router>
  );
};
