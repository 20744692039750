import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from "@mui/material";
import { FC } from "react";
import { X } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Communication } from "../models/communications/communication";
import { CommunicationActionType } from "../models/communications/communicationActionType";

export const CommunicationBanner: FC<{
  communication: Communication;
  markRead: (communicationId: string) => void;
}> = ({ communication, markRead }) => {
  const navigate = useNavigate();

  return (
    <Grid item mb={2}>
      <Card>
        <CardHeader
          title={
            <Box display="flex">
              <Typography variant="h6">{communication.title}</Typography>{" "}
              <X
                style={{ marginLeft: "auto", cursor: "pointer" }}
                onClick={() => markRead(communication.id)}
              />
            </Box>
          }
          sx={{ pb: 0 }}
        />
        <CardContent sx={{ py: 1 }}>
          <Typography color="grey">{communication.content}</Typography>
        </CardContent>
        {communication?.actions && (
          <Box display="flex" p={1}>
            <Box ml="auto">
              {communication.actions.map((a) => {
                return (
                  <Button
                    onClick={() => {
                      navigate(a.href);
                      markRead(communication.id);
                    }}
                    variant={
                      a.type === CommunicationActionType.PRIMARY
                        ? "contained"
                        : "outlined"
                    }
                  >
                    {a.title}
                  </Button>
                );
              })}
            </Box>
          </Box>
        )}
      </Card>
    </Grid>
  );
};
