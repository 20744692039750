import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../utils/useConfig";
import { LoadingSpinner } from "./LoadingSpinner";

type AppState = {
  redirectTo?: string;
};

/**
 * Utility component helping providers that rely on useConfig to be
 * placed inside index.tsx so that App.tsx can consume them
 */
export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const { config, isLoading, error } = useConfig();
  const navigate = useNavigate();

  if (error) return <p>{error.message}</p>;

  if (isLoading || !config) return <LoadingSpinner />;

  return (
    <KindeProvider
      audience={config.KINDE_AUDIENCE}
      clientId={config.KINDE_CLIENT_ID}
      domain={config.KINDE_DOMAIN}
      logoutUri={window.location.origin}
      redirectUri={window.location.origin}
      onRedirectCallback={(_, appState) => {
        const state = appState as AppState | undefined;
        if (state?.redirectTo) {
          navigate(state.redirectTo);
        }
      }}
    >
      {children}
    </KindeProvider>
  );
};
