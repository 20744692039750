import { Add } from "@mui/icons-material";
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import { ServiceSector } from "../models";

type AcceptedServicesListProps = {
  serviceSectors?: ServiceSector[];
};

export const AcceptedServicesList = ({
  serviceSectors
}: AcceptedServicesListProps) => {
  if (!serviceSectors?.length)
    return <Typography my={2}>No accepted service sectors</Typography>;

  return (
    <List>
      {serviceSectors?.map((serviceSector, index) => (
        <ListItem key={index}>
          <ListItemIcon>
            <Avatar sx={{ bgcolor: "primary.main" }}>
              <Add
                style={{
                  color: "primary.light"
                }}
                fontSize="large"
              />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            style={{ marginLeft: 5 }}
            primary={serviceSector.name}
            secondary={serviceSector?.description}
          />
        </ListItem>
      ))}
    </List>
  );
};
