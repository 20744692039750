import { SectionHeader } from "../../components/SectionHeader";
import { PersonalDetailsForm } from "../../components/PersonalDetailsForm";

export const Profile = () => {
  return (
    <>
      <SectionHeader text="Your account" showBackButton />
      <PersonalDetailsForm showCancel={true} submitButtonText="Save" />
    </>
  );
};
