import { isRight } from "fp-ts/lib/Either";
import * as t from "io-ts";
import { useQuery } from "react-query";

const AppConfig = t.type({
  KINDE_AUDIENCE: t.string,
  KINDE_CLIENT_ID: t.string,
  KINDE_DOMAIN: t.string,
  API_URL: t.string,
  PERSONA_TEMPLATE_ID: t.union([t.string, t.undefined]),
  PERSONA_ENVIRONMENT_ID: t.union([t.string, t.undefined]),
  ACTIVATE_CARD_WAIT_HOURS: t.number,
  ADDRESS_FINDER_KEY: t.union([t.string, t.undefined]),
  // IS_PHASE_TWO: t.union([t.boolean, t.undefined]),
  PUBLIC_BUCKET_URL: t.union([t.string, t.undefined]),
  ZENDESK_KEY: t.union([t.string, t.undefined])
});

type AppConfigType = t.TypeOf<typeof AppConfig>;

function validateConfig(config: any): AppConfigType {
  const result = AppConfig.decode(config);
  if (!isRight(result))
    throw new Error("An error occurred loading the environment configuration");

  return result.right;
}

export function useConfig() {
  const {
    data: config,
    isLoading,
    error
  } = useQuery("/assets/config/config.json", fetchConfig, {
    onError: (err: Error) => err
  });

  async function fetchConfig() {
    try {
      const response = await fetch("/assets/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      });

      if (!response.ok) {
        throw new Error(`Error loading config: ${response.status}`);
      }

      const configData = await response.json();
      return validateConfig(configData);
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        if (error instanceof Error) {
          throw new Error(
            `An error occurred loading the environment configuration: ${error.message}`
          );
        } else {
          throw new Error(
            "An unknown error occurred loading the environment configuration"
          );
        }
      }
    }
  }

  return { config, isLoading, error };
}
