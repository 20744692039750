import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import { useAddToHomescreenPrompt } from "../../../utils/useAddToHomescreenPrompt";
import { Prompt } from "./Prompt";

const AndroidInstallPrompt: FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose
}) => {
  const [promptable, promptToInstall, isInstalled] = useAddToHomescreenPrompt();

  if (!promptable || isInstalled) return null;

  return (
    <Prompt open={open} onClose={onClose}>
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        color="white"
        justifyContent="center"
      >
        <Typography>Install our Web-App</Typography>
        <Button variant="outlined" color="inherit" onClick={promptToInstall}>
          Add to Home Screen
        </Button>
      </Box>
    </Prompt>
  );
};

export default AndroidInstallPrompt;
