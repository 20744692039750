import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

type SectionHeaderProps = {
  text: string;
  showBackButton?: boolean;
};

export const SectionHeader = ({ text, showBackButton }: SectionHeaderProps) => {
  const navigate = useNavigate();

  function handleBack() {
    navigate(-1);
  }

  return (
    <Box sx={{ display: "flex", marginBottom: 2, alignItems: "center" }}>
      {showBackButton && (
        <IconButton onClick={handleBack}>
          <ArrowBack />
        </IconButton>
      )}
      <Typography variant="h5">{text}</Typography>
    </Box>
  );
};
