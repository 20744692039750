import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { Share } from "react-feather";
import { Prompt } from "./Prompt";

const IosInstructionsPrompt: FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose
}) => {
  const isSafari = () => {
    return (
      navigator.userAgent &&
      navigator.userAgent.indexOf("CriOS") === -1 &&
      navigator.userAgent.indexOf("FxiOS") === -1 &&
      navigator.userAgent.indexOf("Opera") === -1
    );
  };

  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  if (isInStandaloneMode() || !isSafari()) return null;

  return (
    <Prompt open={open} onClose={onClose} arrow={true}>
      <Typography fontWeight={500}>
        To add this app on your apple device:
      </Typography>
      <Box display="flex" alignItems="center" gap={1} mt={1}>
        <Typography>Tap</Typography>
        <Typography color="white">
          <Share height={25} />
        </Typography>
        <Typography>and then:</Typography>
        <Typography fontWeight={500} color="white">
          Add to Home Screen
        </Typography>
      </Box>
    </Prompt>
  );
};

export default IosInstructionsPrompt;
