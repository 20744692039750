import { useEffect, useState } from "react";
import AndroidInstallPrompt from "./components/AndroidInstallPrompt";
import IosInstructionsPrompt from "./components/IosInstructionsPrompt";

const PwaPrompt = () => {
  const [open, setOpen] = useState(true);
  const [pwaPrompted, setPwaPrompted] = useState(
    window.sessionStorage.getItem("pwa-prompt") === "true"
  );

  const [width, setWidth] = useState(window.innerWidth);

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const handleClose = () => {
    setOpen(false);
    setPwaPrompted(true);
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.sessionStorage.setItem("pwa-prompt", "true");

    if (pwaPrompted) {
      setOpen(false);
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [pwaPrompted]);

  if (width > 768 || pwaPrompted) return null;

  return isIos() ? (
    <IosInstructionsPrompt open={open} onClose={handleClose} />
  ) : (
    <AndroidInstallPrompt open={open} onClose={handleClose} />
  );
};

export default PwaPrompt;
