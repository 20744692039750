import { Divider, List, Typography } from "@mui/material";
import { Fragment } from "react";
import { Transaction, TransactionStatus } from "../models";
import { sortDate } from "../utils/date";
import { TransactionListItem } from "./TransactionListItem";

type TransactionProps = {
  transactions: Transaction[];
  limit?: number;
};

export const TransactionList = ({ transactions, limit }: TransactionProps) => {
  if (!transactions?.length) return <Typography>No charges</Typography>;

  const transactionsSorted = transactions
    .filter(
      (transaction) =>
        transaction.status === TransactionStatus.APPROVED.toString() ||
        transaction.status === TransactionStatus.DECLINED.toString() ||
        transaction.status === TransactionStatus.PENDING.toString()
    )
    .sort((a, b) => sortDate({ dateA: b.timestamp, dateB: a.timestamp }));

  const limitedTransactions = limit
    ? transactionsSorted.slice(0, limit)
    : transactionsSorted;

  return (
    <List
      sx={{
        overflow: "auto"
      }}
    >
      <Divider />
      {limitedTransactions?.map((transaction, index) => (
        <Fragment key={index}>
          <TransactionListItem transaction={transaction} />
          <Divider />
        </Fragment>
      ))}
    </List>
  );
};
