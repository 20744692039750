import { TextField } from "@mui/material";
import {
  ChangeEvent,
  KeyboardEvent,
  RefObject,
  createRef,
  useEffect
} from "react";

type CodeInputProps = {
  code: string[];
  setCode: React.Dispatch<React.SetStateAction<string[]>>;
};

export const CodeInput = ({ code, setCode }: CodeInputProps) => {
  const inputRefs: RefObject<HTMLInputElement>[] = Array.from(
    { length: code.length },
    () => createRef()
  );

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) && newValue.length <= 1) {
      const newCode = [...code];
      newCode[index] = newValue;
      setCode(newCode);

      if (newValue && index < code.length - 1) {
        inputRefs[index + 1].current?.focus();
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && index > 0 && !code[index]) {
      inputRefs[index - 1].current?.focus();
    }
  };

  // Resets focus to first code input index if empty (eg. multi-step use-cases - see ActivateCard, ResetPin)
  useEffect(() => {
    if (!code[0]?.length) inputRefs[0]?.current?.focus();
  }, [code, inputRefs]);

  return (
    <>
      {code.map((digit, index) => (
        <TextField
          key={index}
          inputProps={{ inputMode: "decimal" }}
          inputRef={inputRefs[index]}
          variant="outlined"
          margin="normal"
          type="number"
          sx={{
            width: "60px",
            "& input": {
              textAlign: "center",
              fontSize: "1.25rem"
            }
          }}
          autoFocus={index === 0}
          value={digit}
          onChange={(e) =>
            handleInputChange(e as ChangeEvent<HTMLInputElement>, index)
          }
          onKeyDown={(e) =>
            handleKeyDown(e as KeyboardEvent<HTMLInputElement>, index)
          }
        />
      ))}
    </>
  );
};
