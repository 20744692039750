import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useMutation } from "react-query";
import { callApi } from "../utils/fetchData";
import { useConfig } from "../utils/useConfig";
import { useData } from "../utils/useData";
import { CommunicationBanner } from "./CommunicationBanner";

export const CommunicationsBanners = () => {
  const { employer, userInfoData, communications } = useData();
  const { config } = useConfig();
  const { getToken } = useKindeAuth();

  const markCommunicationRead = useMutation(
    async (communicationId: string) => {
      await callApi(
        `${config?.API_URL}/communications/${communicationId}/read`,
        "PUT",
        JSON.stringify({
          employerId: employer.data?.id,
          employeeId: userInfoData?.employeeId
        }),
        getToken
      );
    },
    {
      onSuccess: () => {
        communications.refetch();
      },
      onError: (error: Error) => {
        console.error(error.message);
      }
    }
  );

  return (
    <>
      {communications.data?.items
        .filter((c) => !c.isRead)
        .map((c) => {
          return (
            <CommunicationBanner
              communication={c}
              markRead={(communicationId: string) =>
                markCommunicationRead.mutate(communicationId)
              }
            />
          );
        })}
    </>
  );
};
