import { Box, Dialog, DialogContent, Paper, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";

export const Prompt: FC<{
  open: boolean;
  onClose: () => void;
  arrow?: boolean;
  children: ReactNode | ReactNode[];
}> = ({ open, onClose, arrow, children }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
      PaperComponent={(props) => {
        return (
          <Paper
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              borderRadius: 10,
              backgroundColor: "transparent",
              margin: 15,
              marginBottom: 0,
              width: "calc(100% - 30px)",
              boxShadow: "none"
            }}
            {...props}
          />
        );
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: 2,
          backgroundColor: theme.palette.primary.main,
          borderRadius: 2,
          marginBottom: arrow ? 0 : 2
        }}
      >
        {children}
      </DialogContent>
      {arrow && (
        <Box
          marginLeft="auto"
          marginRight="auto"
          width={0}
          height={0}
          borderLeft={`15px solid transparent`}
          borderRight={`15px solid transparent`}
          borderTop={`20px solid ${theme.palette.primary.main}`}
        />
      )}
    </Dialog>
  );
};
