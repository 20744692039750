import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { X } from "react-feather";

export const LocalCommunicationBanner: FC<{
  title: string;
  description: string;
  onClose?: () => void;
  children?: ReactNode | ReactNode[];
}> = ({ title, description, onClose, children }) => {
  const [show, setShow] = useState(true);

  if (!show) return null;
  return (
    <Grid item>
      <Card>
        <CardHeader
          title={<Typography variant="h6">{title}</Typography>}
          action={
            <X
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShow(false);
                if (onClose) onClose();
              }}
            />
          }
          sx={{ pb: 0 }}
        />
        <CardContent sx={{ py: 1 }}>
          <Typography color="grey">{description}</Typography>
        </CardContent>
        {children}
      </Card>
    </Grid>
  );
};
