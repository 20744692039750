import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  Typography,
  useTheme
} from "@mui/material";
import { FC, useState } from "react";
import { Check, ChevronDown, ChevronUp, Link, X } from "react-feather";
import { Transaction, TransactionReason, TransactionStatus } from "../models";
import { formatCurrency, formatDate } from "../utils/formatters";
import { useData } from "../utils/useData";

export const TransactionListItem: FC<{
  transaction: Transaction;
}> = ({ transaction }) => {
  const { employee } = useData();

  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const displayDeclineReason = (transaction: Transaction) => {
    const displayReason = (
      text: string,
      moreInformationUrl: string | null = null,
      moreInformationLabel: string | null = null
    ) => (
      <>
        <ListItemText secondary={text} />
        {moreInformationUrl && (
          <Link href={moreInformationUrl}>{moreInformationLabel}</Link>
        )}
      </>
    );
    switch (transaction.reason) {
      case TransactionReason.MCC_CODE_NOT_ALLOWED:
        return displayReason(
          "The transaction was attempted at a provider that falls under a category not allowed by your company's policies. Please talk to your employer."
        );
      case TransactionReason.CARD_NOT_ACTIVATED:
        return displayReason(
          "The card has not been activated.",
          "/activate-card",
          "Activate your card now"
        );
      case TransactionReason.CARD_NOT_ACTIVE:
        return displayReason(
          "The card has been deactivated. Please talk to your employer."
        );
      case TransactionReason.CARD_EXPIRED:
        return displayReason(
          "The card has expired. Please talk to your employer."
        );
      case TransactionReason.EMPLOYEE_HAS_NO_ACCOUNTS:
      case TransactionReason.EMPLOYEE_HAS_NO_ACTIVE_ACCOUNTS:
        return displayReason(
          "There is no active plan with HealthNow. Please talk to your employer."
        );
      case TransactionReason.INSUFFICIENT_FUNDS:
        return displayReason(
          "There were insufficient funds to complete this transaction. Please talk to your employer."
        );
      case TransactionReason.WRONG_CSV2:
        return displayReason(
          "The security code provided did not match the code on the card."
        );
      case TransactionReason.INCORRECT_PIN:
      case TransactionReason.PIN_TRIES_EXCEEDED:
        return displayReason(
          "The PIN entered did not match the PIN on the card.",
          "/reset-pin",
          "Set PIN now"
        );
      case TransactionReason.CARD_NOT_FOUND:
      case TransactionReason.EMPLOYEE_NOT_FOUND:
      case TransactionReason.EMPLOYEE_NOT_ACTIVE:
      case TransactionReason.EMPLOYEE_DELETED:
      case TransactionReason.ACCOUNTS_FROM_ORIG_TRANSACTION_NOT_FOUND:
      case TransactionReason.ORIGINAL_TRANSACTION_NOT_FOUND:
      case TransactionReason.NO_ORIGINAL_TRANSACTION_IN_REQUEST:
      case TransactionReason.UNEXPECTED_ERROR:
      case TransactionReason.DECLINE:
      case TransactionReason.AUTHORISATION_EXPIRED:
      case TransactionReason.CARD_DELETED:
      case TransactionReason.APPROVE:
      default:
        return <></>;
    }
  };

  return (
    <ListItem>
      <Grid container>
        <Grid item xs={12}>
          {/* First Row */}
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              {transaction.status === TransactionStatus.DECLINED.toString() ? (
                <X color="red" />
              ) : (
                <Check color="green" />
              )}
            </Grid>
            <Grid item xs>
              <ListItemText
                primary={transaction.cardAcceptor ?? ""}
                secondary={formatDate(transaction.timestamp)}
              />
            </Grid>
            <Grid item>
              <Typography>
                {formatCurrency(transaction.billingAmount.amount)}
              </Typography>
            </Grid>
          </Grid>
          {((transaction.charges?.length && transaction.charges.length > 1) ||
            transaction.status === TransactionStatus.DECLINED.toString()) && (
            <Grid item>
              <Box display="flex" onClick={() => setIsOpen(!isOpen)}>
                <Typography
                  ml="auto"
                  fontSize={14}
                  color="primary"
                  style={{ cursor: "pointer" }}
                >
                  More info
                </Typography>
                {isOpen ? (
                  <ChevronUp
                    size={20}
                    color={theme.palette.primary.main}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <ChevronDown
                    size={20}
                    color={theme.palette.primary.main}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Box>
            </Grid>
          )}
        </Grid>
        {/* Second Row */}
        <Grid width="100%">
          {isOpen &&
            (transaction.status === TransactionStatus.DECLINED.toString() ? (
              <>{displayDeclineReason(transaction)}</>
            ) : (
              transaction.charges?.length &&
              transaction.charges.length > 1 && (
                <>
                  <Typography color="secondary" fontSize={14}>
                    This transaction was split over multiple accounts
                  </Typography>
                  {transaction.charges.map((charge) => {
                    let account = employee.data?.accounts.find(
                      (a) => a.id === charge.accountId
                    );
                    return (
                      <Grid>
                        <Box display="flex">
                          <Typography fontSize={14} color="secondary" ml="auto">
                            {account?.name}
                          </Typography>
                          <Typography
                            fontSize={14}
                            textAlign="right"
                            color="secondary"
                            width={60}
                          >
                            {formatCurrency(charge.amount.amount)}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                </>
              )
            ))}
        </Grid>
      </Grid>
    </ListItem>
  );
};
